@use "../../../../../styles/variables.module" as *;

:global(.sb-with-background-image) .container ul {
  @media (min-width: $break-point-sm) {
    background: white;
    box-shadow: 2px 2px 5px 2px lightgray;
    border-radius: 20px;
  }
}

.container {
  min-height: 167px;
  position: relative;

  &IsEditor {
    @media(max-width: $break-point-sm) {
      padding-top: 50px;
    }
  }

  ul {
    list-style: none;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 15px 0;

    li {
      line-height: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding: 10px 10px 10px 10px;

      button {
        span {
          position: relative;
          z-index: 1;
          font-size: 2rem;
        }
      }

      span {
        font-size: 6rem;
      }

      a {
        color: var(--blockBgTextColorNoBlocksUseSecondary);
        display: block;
        height: 100%;

        div {
          margin-top: 15px;
          color: $black;
        }
      }
    }
  }

  .icon {
    flex-basis: 252px;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    margin: 5px 0px;
    &Reorder {
      li {
        a {
          cursor: move;
          animation: tilt-shaking 0.5s infinite;
        }
      }
    }
    li {
      transition: margin-top, cubic-bezier(0.25, 1, 0.5, 1) 300ms;
    }
    &:hover {
      li {
        opacity: .7;
        margin-top: -10px;
        padding-bottom: 20px;
        font-weight: 550;
      }
    }
    &Editing {
      &:hover {
        li {
          margin-top: 0px;
          padding-bottom: 10px;
        }
      }
    }
    @media(min-width: $break-point-lg) {
      flex-basis: 16.6667%;
    }
    @media(max-width: $break-point-sm) {
      flex-basis: 50%;
    }
  }
}

.reorder {
  border: 1.5px solid $gray;
  border-radius: 5px;
}

.editorButton {
  font-size: 25px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  @media(max-width: $break-point-sm) {
    height: 25px;
    width: 25px;
    line-height: 25px;
  }
}

.editingButtons {
  position: absolute;
  left: 5vw;
  top: 50%;
  transform: translateY(-50%);
  @media(min-width: $break-point-md) {
    button {
      display: block;
    }
  }
  @media(max-width: $break-point-sm) {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}


@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
